<template>
	<div class="content-wrap">
		<van-form @submit="changeStep">
			<div class="section-top">
				<h4 class="title">
					<span class="txt"><span class="line"></span>填写身份信息<span class="line"></span></span>
				</h4>
				<div class="form">
					<!-- studentName -->
					<van-field
						v-model="form.studentName"
						clearable
						label=""
						:rules="[{ required: true, message: '请输入真实姓名' }]"
						placeholder="请输入真实姓名"
					>
						<img
							slot="left-icon"
							class="icon-img"
							:src="require('../../assets/icon/icon-man-sm'+(appno==1?'':appno)+'.png')"
							alt="姓名"
						/>
					</van-field>
					<!-- certNum -->
					<van-field
						v-model="form.certNum"
						clearable
						maxlength="18"
						:rules="[{ pattern: idCardPattern, message: '请输入正确的证件号' }]"
						label=""
						placeholder="请输入报名的证件号"
					>
						<img
							slot="left-icon"
							class="icon-img"
							:src="require('../../assets/icon/icon-idcard-sm'+(appno==1?'':appno)+'.png')"
							alt="证件号"
						/>
					</van-field>
					<van-field
						v-model="form.mobile"
						clearable
						maxlength="11"
						:rules="[{ pattern: mobilePattern, message: '请输入正确的手机号' }]"
						label=""
						placeholder="请输入手机号"
					>
						<img
							slot="left-icon"
							class="icon-img"
							src="../../assets/icon-form-phone-app02.png"
							alt="手机号"
						/>
					</van-field>
					<van-field
						v-model="form.code"
						clearable
						maxlength="18"
						:rules="[{ required: true, message: '请输入正确的验证码' }]"
						label=""
						placeholder="请输入验证码"
					>
						<img
							slot="left-icon"
							class="icon-img"
							src="../../assets/icon-form-code-app02.png"
							alt="验证码"
						/>
						<span slot="button" v-if="isCodeCount" class="text_primary" style="text-decoration: underline; padding-right: 19px;font-size: 12px;">{{  codeCount  }}S</span>
						<span slot="button" v-else class="text_primary" @click="clickGetCode" style="text-decoration: underline; padding-right: 19px;font-size: 12px;">获取验证码</span>
					</van-field>
					<van-field name="uploader" label="" class="photo">
						<template #input>
							<div style="width: 55%;font-size: 12px;">上传本人近期照片<br><span style="color:#ff0000;">照片规范：1.面部无遮挡，头像部约占照片高度三分之二。2.可以佩戴眼镜，但注意不要反光。3.尽量背景单一，光线均匀，无逆光。</span></div>
							<van-uploader v-model="photoList" style="margin-left:0.34rem;" :after-read="onRead" multiple :max-count="1"/>
						</template>
					</van-field>
				</div>
			</div>
			<div class="section-bottom">
				<van-button
					class="btn"
					native-type="submit"
					type="primary"
					block
					:loading="loading"
					loading-text="提交中..."
				>下一步
				</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
import { checkStudent,wxAuth,getPhoneCode,checkCode } from '../../api/user'
import { wipeBase64Rrefix } from "../../utils";
import Validation from '@/utils/validation'
	export default {
		name: "shandongOne",
		data() {
			return {
				loading: false,
				step: 0,
				form: {
					studentName: "",
					certNum: "",
					studentPic: '',
					mobile: '',
					code: '',
				},
				objform: {
					studentName: "",
					certNum: "",
				},
				photoList: [],
				mobilePattern: /^1[0-9]{10}$/,
				idCardPattern: /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))$/,
				isCodeCount: false,
				codeCount: 60,
				codeTimer: null,
				rules: {
					mobile: [
						{ required: true, message: '手机号不能为空' },
						{ count: 11, message: '手机号码位数输入有误，请重新输入' }
					],
					code: [
						{ required: true, message: '验证码不能为空' },
						{ count: 6, message: '验证码输入有误，请重新输入' }
					]
				},
			};
		},
		mounted() {
			const openid = location.search
			if (this.$store.state.cache.screen.wx && !openid.includes('openId=')) {
				let href = window.location.href.replace('login', 'shandong')
				wxAuth('', href).then(({url}) => {
					window.location.href = url
				})
			}
			console.log(this.step);
			this.form.certNum = localStorage.getItem('idCard')
		},
		computed: {
			appno() {
				return this.$store.state.app.appno
			},
		},
		methods: {
			onRead(file) {
				this.form.studentPic = wipeBase64Rrefix(file.content);
			},
			changeStep() {
				if (this.form.studentName && this.form.certNum && this.form.mobile && this.form.code && this.form.studentPic) {
					this.objform.studentName = this.form.studentName;
					this.objform.certNum = this.form.certNum;
					sessionStorage.setItem('form', JSON.stringify(this.objform))
					checkCode({ mobile: this.form.mobile,code: this.form.code }).then((res) => {
						if (res.code===200) {
							this.toLogin();
						}else{
							this.$utils.dialog.alert(msg)
						}
					})
				}
			},
			// 开始倒计时
			beginCodeComputed () {
				const _this = this
				this.codeCount = 60
				this.isCodeCount = true
				const beginTimetamp = new Date().getTime()
				this.codeTimer = setInterval(() => {
					const curTimetamp = new Date().getTime()
					const curTimeVal = Math.floor((curTimetamp - beginTimetamp) / 1000)
					if (curTimeVal >= 60) {
						clearInterval(_this.codeTimer)
						_this.isCodeCount = false
					} else {
						_this.codeCount = 60 - curTimeVal
					}
				}, 1000)
			},
			clickGetCode() {
				const message = new Validation({ mobile: this.rules.mobile },{ mobile: this.form.mobile}).start()
				console.log(this.form.mobile);
				if (message) {
					this.$utils.dialog.alert(message)
				}else{
					getPhoneCode({ mobile: this.form.mobile }).then((res) => {
						this.beginCodeComputed()
						if (res.data.smsCode) {
							this.form.code = res.data.smsCode
						}
					})
				}
			},
			flagStr () {
				setTimeout(()=> {
					this.loading = false;
				},2000)
			},
			toLogin() {
				this.loading = true;
				checkStudent(this.form).then((data) => {
					console.log(data);
					if (this.$store.state.cache.screen.wx) {
						const openid = location.search
						sessionStorage.setItem('openid', openid.split('=')[1])
						sessionStorage.setItem('HJuserid', JSON.stringify(data.data.basePkId))
						this.$store.commit('cache/set_checkStudent', {checkStudent: data.studentInfo[0]})
						this.flagStr();
						this.step++;
						this.$emit("changeStep", this.step);
					} else {
						this.$store.commit('cache/set_checkStudent', {checkStudent: data.studentInfo[0]})
						sessionStorage.setItem('HJuserid', JSON.stringify(data.data.basePkId))
						this.flagStr();
						this.step++;
						this.$emit("changeStep", this.step);
					}
				}).catch(() => {
					this.loading = false;
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.van-field__left-icon {
		margin-right: 8px;
	}

	.icon-img {
		width: 15px;
		vertical-align: text-bottom;
	}

	// .photo::-webkit-scrollbar {
	// 	display: none;
	// }

	.content-wrap {
		.van-cell {
			margin-top: 10px;
			// box-shadow: 0 0 8px 0px rgba(0,0,0,0.15);
			box-shadow: 0px 2px 8px 0px rgba(210, 210, 210, 0.48);
			border-radius: 8px;

			&::after {
				border: none;
			}

			.van-field__left-icon {
				margin-right: 8px;
			}
		}

		.van-field__left-icon {
			margin-right: 8px;
		}

		.section-top {
			padding: 26px 15px 60px;
			box-shadow: 0px 2px 8px 1px #e1e0df;
			border-radius: 14px;

			.title {
				position: relative;
				font-size: 17px;
				text-align: center;

				.txt {
					position: relative;
				}

				.line {
					position: absolute;
					top: 50%;
					display: inline-block;
					width: 30px;
					height: 2px;
					background: #fca142;
					margin: -1px 0 0;

					&:nth-of-type(1) {
						left: -42px;
					}

					&:nth-of-type(2) {
						right: -42px;
					}
				}
			}

			.form {
				margin-top: 18px;
				.picture {
					margin-top: 60px;

					.desc {
						line-height: 1.6;
					}

					.img-wrap {
						display: block;
						max-width: 80%;
						width: 108px;
						height: 138px;
						padding: 2px;
						border: 2px solid #F0F1F6;
						margin: 20px auto;

						.img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}

						.upload-wrap {
							position: relative;
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 100%;
							height: 100%;

							.icon {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;

								span {
									display: inline-block;
									margin-top: 6px;
									font-size: 13px;
									color: #666;
								}

								.img-input {
									position: absolute;
									width: 100%;
									height: 100%;
									opacity: 0;
								}
							}
						}
					}
				}
			}
		}

		.section-bottom {
			margin-top: 40px;
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.content-wrap {
			.section-top {
				.title {
					.line {
						background: @colorSecond;
					}
				}
			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.content-wrap {
			.section-top {
				.title {
					.line {
						background: @colorSecond;
					}
				}
			}
		}
	}
</style>
