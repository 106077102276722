<template>
	<div class="content-wrap">
		<van-notice-bar color="#11A197" background="#F0F9EB" left-icon="volume-o" :scrollable="true">
			学员您好！请点击您将要进行的课程类型进入学习，并祝您顺利结业！
		</van-notice-bar>
		<div class="section-top">
			<div v-for="(item,index) in listname" :key="index" id="wrap-box" class='wrap-box' @click="deny(index)" :class="isactive == index ? 'addclass' : '' || (listname[index].sta !== '1' && isActivate !== 1) || (iscolors[index] >= '180' && isActivate !== 1 || drivingClass.includes('C1') && listname[index].value == '02001') ? 'wrap-box1' : 'wrap-box' ">
				<div class="circular"></div>
				<div>{{listname[index].text}}</div>
			</div>
		</div>
			<p v-if="this.categoryId!=118" style="color:red; text-align: left; font-size:130%; text-indent:2em;">道路运输驾驶员继续教育周期为2年，在每个周期接受继续教育的时间累计应不少于24学时。请严格按照学习周期进行学习。</p>
			<p v-if="this.show" style="color:red; text-align: center; font-size:130%; text-indent:2em;">未查询到资格证信息</p>
		<div class="section-bottom">
			<van-button
				class="btn"
				type="primary"
				block
				@click="changeStep"
				:disabled = flag
			>下一步
			</van-button>
		</div>
		<van-popup round v-model="ismesssge" class="prompt-box">
          <div class="prompt-wrap">
			<div class="prompt-tip">提&nbsp;&nbsp;示</div>
            <div class="prompt-text" v-if="listname[isactive] !== undefined">
              <p>您选择的课程是:<span>{{listname[isactive].text}}</span></p>
			  <p>一经确认无法修改，请与管理员联系!</p>
            </div>
            <div class="prompt-btns">
              <van-button @click="cancle" class="btns-btn">重新选课</van-button>
              <van-button @click="confirm" :loading="uploading" class="btns-btn">确认课程</van-button>
            </div>
          </div>
        </van-popup>
	</div>
</template>

<script>
// import {getStudentInfoHJ,updateStudentHJ,getPayStatus,wxConfig} from '../../api/user';
import {updateStudentHJ,getPayStatus,wxConfig,checkPayStatus,updateCertificateSd} from '../../api/user';

import {Toast} from "vant";
export default {
	name: 'shandongTwo',
	data() {
		return {
			step: 1,
			uploading: false,
			showNotMe: true,
			categoryId: null,
			show:false,
			form: {
				// studentName: "张三三",
				// certNum: "370101198502252222",
				studentName: "",
				certNum: "",
				qualificationType: '',
				qualificationId: '',
				telephone: '',
				staffId: '',
			},
			list: [],
			listname: [],
			HJuserid: '',
			isactive: -1,
			flag: true,
			ismesssge: false,
			// iscolor: [],
			iscolors: [],
			isActivate: '',
			drivingClass: '',
		}
	},
	created() {
		this.categoryId = localStorage.getItem('categoryId')
		let d2 = sessionStorage.getItem('form');
		console.log("d2=", d2)
		this.form.studentName = JSON.parse(d2).studentName;
		console.log("studentName=", this.form.studentName)
		this.form.certNum = JSON.parse(d2).certNum;
		console.log("certNum=", this.form.certNum)
		this.HJuserid = sessionStorage.getItem('HJuserid')
		console.log("HJuserid=", this.HJuserid)
		this.getStudentInfoHJ();
	},
	mounted() {
		console.log(window.location.href)
	},
	computed: {
		userid() {
			return this.$store.state.user.userid
		},
		paystatus() { // 支付状态
			return this.$store.state.user.paystatus
		},
		checkStudent() { // 学员信息
			return this.$store.state.cache.checkStudent
		},
	},
	methods: {
		// getStudentInfoHJ() {
		// 	console.log('调用getStudentInfoHJ');
		// 	getStudentInfoHJ({
		// 		staffName: this.form.studentName,
		// 		staffIDCardNo: this.form.certNum,
		// 	}).then((data) => {
		// 		this.form.telephone = data[0].telephone;
		// 		this.form.staffId = data[0].staffId;
		// 		this.isActivate = data[0].isActivate;
		// 		this.list = data[0].qualifications;
		// 		this.drivingClass = data[0].drivingClass;
		// 		this.studentname();
		// 	}).catch(() => {
		// 	})
		// },
		getStudentInfoHJ() {
			console.log('checkStudent',this.checkStudent);
			this.form.telephone = this.checkStudent.telephone;
			this.form.staffId = this.checkStudent.staffId;
			this.isActivate = this.checkStudent.isActivate;
			console.log('isActivate==>',this.isActivate,typeof this.isActivate);
			this.list = this.checkStudent.qualifications;
			this.drivingClass = this.checkStudent.drivingClass;
			this.studentname();
		},
		studentname() {
			let a = [];
			if(this.categoryId!=118){
			this.list.map( v => {
				if (v.qualificationType === '99999') {
					// this.listname.push('网络预约出租汽车驾驶员');
				}else if (v.qualificationType === '01001') {
					// this.listname.push('道路旅客运输驾驶员');
					this.listname.push({text: '道路旅客运输驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '02001') {
					// this.listname.push('道路货物运输驾驶员');
					this.listname.push({text: '道路货物运输驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03001') {
					// this.listname.push('危险货物驾驶员');
					this.listname.push({text: '危险货物驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03002') {
					// this.listname.push('危险货物装卸员');
					this.listname.push({text: '危险货物装卸员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03003') {
					// this.listname.push('危险货物押运员');
					this.listname.push({text: '危险货物押运员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03005') {
					// this.listname.push('剧毒品驾驶员');
					this.listname.push({text: '剧毒品驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03006') {
					// this.listname.push('剧毒品装卸员');
					this.listname.push({text: '剧毒品装卸员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03007') {
					// this.listname.push('剧毒品押运员');
					this.listname.push({text: '剧毒品押运员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03009') {
					// this.listname.push('爆炸品驾驶员');
					this.listname.push({text: '爆炸品驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03010') {
					// this.listname.push('爆炸品装卸员');
					this.listname.push({text: '爆炸品装卸员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03011') {
					// this.listname.push('爆炸品押运员');
					this.listname.push({text: '爆炸品押运员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03013') {
					// this.listname.push('放射品驾驶员');
					this.listname.push({text: '放射品驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03014') {
					// this.listname.push('放射品装卸员');
					this.listname.push({text: '放射品装卸员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '03015') {
					// this.listname.push('放射品押运员');
					this.listname.push({text: '放射品押运员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if (v.qualificationType === '09001') {
					// this.listname.push('巡游出租汽车驾驶员');
				}
				// this.iscolor = this.list.map( v => v.qualificationStatus);
			});
			}else{
				this.list.map( v => {if (v.qualificationType === '99999') {
					this.listname.push({text: '网络预约出租汽车驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}else if(v.qualificationType === '09001'){
					this.listname.push({text: '巡游出租汽车驾驶员', value: v.qualificationType, id: v.qualificationId, sta: v.qualificationStatus});
					a.push(v.validDateEnd);
				}});
			}
			// a = this.list.map( v => v.validDateEnd);
			console.log('过期时间',a);
			if(a.length > 0){
				for (let i = 0; i < a.length; i++) {
					this.day(a[i],i)
				}
			}else{
				this.show = true
			}
			
		},
		day(data,i) {
			let minutes = 1000*60;
			let hours = minutes*60;
			let days = hours*24;
			let d=new Date();
			let t=d.getTime();
			let x=new Date(data).getTime();
			let y=Math.round((t-x)/days);
			console.log(y,i);
			this.iscolors.push(y);
			return this.iscolors;
		},
		deny(index) {
			if (this.listname[index].sta !== '1' && this.isActivate !== 1) { // 课程状态注销、吊销等异常
					this.$set(this.listname,index,this.listname[index])
					Toast.fail("您的资格证为注销、吊销等异常状态，无法继续学习，请咨询当地运输管理部门");
			} else if (this.listname[index].sta === '1' && this.iscolors[index] >= '180' && this.isActivate !== 1) { // 课程状态正常，资格证超期180天以上
					this.$set(this.listname,index,this.listname[index])
					Toast.fail("您的资格证已超期，无法继续学习，请咨询当地运输管理部门");
			} else if(this.drivingClass.includes('C1')&& this.listname[index].value == '02001' && this.isActivate !== 1){ //准驾车型不为C1并且课程编号不为'02001'
					Toast.fail("学员所持证件的准驾车型不在培训范围，请咨询当地运输管理部门。");
			}else {
				this.isactive = index;
				this.form.qualificationId = this.listname[index].id;
				this.form.qualificationType = this.listname[index].value;
				if (this.form.qualificationType) {
					this.flag = false;
				}
			}
		},
		flagStr () {
			setTimeout(()=> {
				this.flag = false;
			},2000)
		},
		changeStep() {
			this.ismesssge = true;
		},
		cancle() {
			this.ismesssge = false;
		},
		confirm() {
			this.flag = true;
			updateStudentHJ({
				staffId: this.form.staffId,
				staffName: this.form.studentName,
				telephone: this.form.telephone,
				idCardNo: this.form.certNum,
				qualificationId: this.form.qualificationId,
				qualificationType: this.form.qualificationType,
			}).then((data) => {
				console.log(data);
				this.flagStr();
				this.uploading = true;
				sessionStorage.setItem('qualificationType', JSON.stringify(this.form.qualificationType))
				const openid = sessionStorage.getItem('openid')
				getPayStatus({
					studentId: this.HJuserid,
					openId: openid
				}).then((data) => {
					this.uploading = false;
					if (data === undefined) {
						this.step++;
						this.$emit('changeStep', this.step)
						console.log("支付完成了")
						this.gotoXM()
					}else{
						if (this.$store.state.cache.screen.wx) {
						this.wxPay(data)
						console.log("准备支付")
						} else if (this.$store.state.cache.screen.isAndroid) {
							window.open(data.wxOrderInfo.mwebUrl)
						}
					}
					
				}).catch(() => {
					this.uploading = false;
				})
			}).catch(() => {
				this.flagStr();
			})
		},
		wxPay (data) {
			const _this = this
			wxConfig().then(res => {
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: res.appId, // 必填，公众号的唯一标识
					timestamp: res.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.nonceStr, // 必填，生成签名的随机串
					signature: res.signature,// 必填，签名
					jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
				})
			})
			wx.ready(() => {
				wx.chooseWXPay({
					timestamp: data.wxOrderInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
					nonceStr: data.wxOrderInfo.nonceStr, // 支付签名随机串，不长于 32 位
					package: data.wxOrderInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
					signType: data.wxOrderInfo.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
					paySign: data.wxOrderInfo.paySign, // 支付签名
					success: function () {
						// 支付成功后的回调函数
						_this.checkPayStatus(data.outTradeNo)						
					},
					fail: function (error) {
						console.log('支付失败：' + error)
					}
				});
			})
		},
		gotoXM() {
			// this.$dialog.alert({
			// 	message: '',
			// 	theme: 'round-button',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#fca142',
			// 	width: 290,
			// }).then(() => {
				updateCertificateSd({
					idCardNo: this.form.certNum,
					qualificationType: this.form.qualificationType,
					trainingOrgName: '北京训美科技有限公司',
				}).then((data) => {
					console.log(data);
					window.location.href = 'https://h5.xunmeizaixian.com/index.html#/train?certNum='+this.form.certNum+ '&appNo=APP02&state=single';
				}).catch(() => {})
			// })
			// .catch(() => {});
		},
		/**
		 * 检测支付状态
		 */
		checkPayStatus  (orderId) {
			checkPayStatus({
				studentId: this.HJuserid,
				outTradeNo: orderId
			}).then(({ payStatus }) => {
				console.log("支付中")
				if (typeof payStatus !== "number") {
					payStatus = Number(payStatus)
				}
				if (payStatus === 1) {
					this.step++;
					this.$emit('changeStep', this.step)
					console.log("支付完成")
					this.gotoXM()
				} else {
					this.checkPayStatus(orderId)
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.content-wrap {
	.section-top {
		width: 80%;
		margin: 40px auto;
		padding: 26px 15px 60px;
		box-shadow: 0px 2px 8px 1px #e1e0df;
		border-radius: 14px;
		.icon{
			margin: 0 auto;
		}
		.wrap-box{
			width: 75%;
			height: 80px;
			margin: 30px auto;
			border-radius: 10px;
			padding: 20px 25px 20px 40px;
			font-size: 19px;
			font-weight: 600;
			color: #2db1ac;
			text-align: center;
			position: relative;
			.circular{
				width: 20px;
				height: 20px;
				border: 1px solid #c4b5b5;
				border-radius: 50%;
				position: absolute;
				top: 30px;
				left: 10px;
			}

		}
		.wrap-box1{
			width: 75%;
			height: 80px;
			margin: 30px auto;
			border-radius: 10px;
			padding: 20px 25px 20px 40px;
			font-size: 19px;
			font-weight: 600;
			color: #c0c0c0;
			text-align: center;
			position: relative;
			.circular{
				width: 20px;
				height: 20px;
				border: 1px solid #c4b5b5;
				border-radius: 50%;
				position: absolute;
				top: 30px;
				left: 10px;
			}

		}
		.addclass .circular{
			background: #2db1ac;
		}
	}
	.prompt-box{
		width: 85%;
		max-width: 510px;
		.prompt-wrap{
			text-align: center;
			.prompt-tip{
				width: 100%;
				height: 30px;
				line-height: 30px;
				font-size: 20px;
				color: #fff;
				background-color: #2db1ac;
			}
			.prompt-text{
				font-size: 15px;
				margin: 25px auto;
				p span{
					color: #ff0000;
					font-weight: 900;
				}
			}
			.prompt-btns{
				width: 100%;
				margin-top: -10px;
				margin-bottom: 25px;
				.btns-btn{
					height: 30px;
					color: #fff;
					background-color: #2db1ac;
					border: 1px solid #2db1ac;
					border-radius: 5px;
				}
				:nth-child(2){
					margin-left: 60px;
				}
			}
}
	}
}
</style>
